import { format as formatDateFns } from 'date-fns';
import { enUS as dateFnsEnUs, de as dateFnsDe } from 'date-fns/locale';
import i18next, { use } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import merge from 'lodash/merge';
import de from './translations/de.json';
import en from './translations/en.json';
import * as oneClickAnalysisDe from './translations/oneClickAnalysisRecommendations/de';
import * as oneClickAnalysisEn from './translations/oneClickAnalysisRecommendations/en';
import { getOneClickAnalysisRecommendations } from './util';

const resources = {
  en: merge(en, getOneClickAnalysisRecommendations(oneClickAnalysisEn)),
  de: merge(de, getOneClickAnalysisRecommendations(oneClickAnalysisDe)),
};

export const i18nLocaleToDateFnsLocale = (locale: string): Locale => {
  switch (locale) {
    case 'de':
      return dateFnsDe;

    default:
      return dateFnsEnUs;
  }
};

use(LanguageDetector).init({
  // we init with resources
  resources,
  fallbackLng: 'en',
  debug: false,
  // have a common namespace used around the full app
  ns: ['global'],
  defaultNS: 'global',
  fallbackNS: '',
  returnedObjectHandler: (_key, _value, _options) => {
    return '';
  },
  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ',',
    alwaysFormat: true,
    format: (value, format, language) => {
      const lng = language?.replace('_', '-') ?? 'en-US';

      if (value && value instanceof Date && format) {
        const locale = i18nLocaleToDateFnsLocale(lng);
        return formatDateFns(value, format, { locale });
      }

      if (typeof value === 'number') {
        return new Intl.NumberFormat(lng.split('-')[0]).format(value);
      }

      return value;
    },
  },
  react: {
    useSuspense: true,
  },
});

export const i18n = i18next;
